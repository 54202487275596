import React from "react";
import { useTitle } from "../hooks/useTitle";
import Lottie from "lottie-react";
import contactLottie from "../assets/contact.json";
import Swal from "sweetalert2";
function Contact() {
  useTitle("Contact");
  return (
    <div className="py-5 px-5 ">
      <div className="text-4xl font-bold text-center mb-5">Contact</div>
      <div className="flex justify-center flex-col items-center">
        <div className="mb-10 relative lg:px-20 justify-center items-center bg-new-blue text-white shadow-[0_5px_30px_-10px_rgba(0,0,0,0.3)] rounded-xl flex flex-col gap-2 p-7">
          {/* <div className="flex flex-row items-center justify-center lg:justify-start">
            <img className="w-[5rem]" src="/mc-transparent.png" alt="logo" />
            <div className="text-xl font-bold">Mark Cay</div>
          </div> */}
          <div className="w-[160px]   rotate-[10deg] -top-[100px] z-[9999999999] mx-auto">
            <Lottie animationData={contactLottie} loop={true} />
          </div>
          <div className="text-xs mt-6 border-t-white border-t-2 pt-3">
            Email me at:
          </div>
          <div className=" text-white font-semibold tracking-wide">
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:markgabrieller@gmail.com"
            >
              markgabrieller@gmail.com
            </a>
          </div>
          <div className="text-xs mt-6 border-t-white border-t-2 pt-3">
            Send me an SMS via:
          </div>
          <div className=" text-white font-semibold tracking-wide">
            <a target="_blank" rel="noreferrer" href="tel:+639508476214">
              +63950 847 6214
            </a>
          </div>
          <div className="text-xs mt-6 border-t-white border-t-2 pt-3">
            Follow me on:
          </div>
          <div className="social-links text-4xl">
            <a
              href="https://twitter.com/markcayofficial"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-twitter "></i>
            </a>
            <a
              href="https://facebook.com/Gabrielle.Cay"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://linkedin.com/in/markcay"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://github.com/markcay"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              href="https://discordapp.com/users/781437984791592990"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-discord"></i>
            </a>
          </div>
        </div>
        <div
          id="payment-methods"
          className="text-4xl font-bold text-center mb-5 mt-20"
        >
          Payment Methods
        </div>
        <div
          id="gcash"
          className="group hover:scale-[102%] transition-default w-full justify-between items-center bg-[#0156E4] text-white shadow-[0_5px_30px_-10px_rgba(0,0,0,0.3)] rounded-xl max-w-[800px] flex flex-col sm:flex-row gap-2 p-7"
        >
          <div className="flex flex-shrink-0 flex-row items-center justify-center lg:justify-start">
            <img
              src="/gcash.webp"
              className="w-[80px] h-[80px] lg:w-[100px] lg:h-[100px] rounded-full group-hover:rotate-3 transition-default"
              alt=""
            />
          </div>
          <div className="flex-1 text-center">
            <div className="text-3xl text-white">GCash</div>
            <div>Mark Gabrielle Recoco</div>
            <div>0950 847 6214</div>
            <br />
            <button
              className="btn bg-white text-new-blue btn-light btn-rounded !p-3"
              onClick={() => {
                Swal.fire({
                  imageUrl: "/gcash-qr.png",
                  background: "#090417",
                  showCloseButton: true,
                  showConfirmButton: false,
                });
              }}
            >
              View my QR Code
              <i className="ml-2 fas fa-chevron-right fa-xs"></i>
            </button>
          </div>
        </div>
        <div
          id="paypal"
          className="mt-5 group hover:scale-[102%] transition-default w-full flex-col justify-between items-center bg-[#103984] text-white shadow-[0_5px_30px_-10px_rgba(0,0,0,0.3)] rounded-xl max-w-[800px] flex sm:flex-row gap-2 p-7"
        >
          <div className="flex  flex-shrink-0 flex-row items-center justify-center lg:justify-start">
            <img
              src="/paypal.svg"
              className="bg-white p-2 w-[80px] h-[80px] lg:w-[100px] lg:h-[100px] rounded-full group-hover:rotate-3 transition-default"
              alt=""
            />
          </div>
          <div className="flex-1 text-center">
            <div className="text-3xl text-white">PayPal</div>
            <div>Mark Gabrielle Recoco</div>
            <div className="font-bold">@markrecoco</div>
            <br />
            <a
              href="https://www.paypal.com/paypalme/markrecoco"
              target="_blank"
              className="btn bg-white text-new-blue btn-light btn-rounded !p-3"
              rel="noreferrer"
            >
              Click to pay
              <i className="ml-2 fas fa-chevron-right fa-xs"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
