import React, { useEffect, useState } from "react";
import { useTitle } from "../hooks/useTitle";
const moment = require("moment");
const workExperienceList = [
  {
    imageUrl: "company-logo/zerobstacle.png",
    client: "Zerobstacle Technologies",
    workAs: "Full-time",
    jobTitle: "Senior Programmer",
    clientLocation: "Puerto Princesa, Palawan, Philippines",
    timeline: {
      from: new Date("2019-09-01"),
      to: null,
    },
    description: `At Zerobstacle Technologies, we build cross-platform mobile apps
  and web systems for ride-hailing, courier delivery, and other
  transport-related services.`,
  },
  {
    imageUrl: "company-logo/evaheld.ico",
    client: "Evaheld",
    workAs: "Freelance",
    jobTitle: "Full Stack Developer",
    clientLocation: "608 Harris Street, Ultimo NSW 2007, Australia",
    timeline: {
      from: new Date("2022-07-01"),
      to: new Date("2022-08-01"),
    },
    description: `Create personalised content for your loved ones and leave less unsaid - because connection is all we have.`,
  },
  {
    imageUrl: "company-logo/argila.png",
    client: "Argila",
    workAs: "Contract",
    jobTitle: "Angular BaaS Developer",
    clientLocation: "Pasig, National Capital Region, Philippines",
    timeline: {
      from: new Date("2022-06-01"),
      to: new Date("2022-07-01"),
    },
    description: `Argila is an online platform for individuals/companies to advertise their professional services to a looking public; individuals/companies may also post job orders that job-seekers may respond to.`,
  },
  {
    imageUrl: "company-logo/backride-palawan.png",
    client: "Backride Palawan",
    workAs: "Part-time",
    jobTitle: "Full Stack Developer",
    clientLocation: "Puerto Princesa, Palawan, Philippines",
    timeline: {
      from: new Date("2021-01-01"),
      to: new Date("2022-04-01"),
    },
    description: `Experience the convenience of RIDE, RENT, SHOP and REQUEST on this unique and beautiful Android App! By Palaweños for Palaweños!`,
  },
  {
    imageUrl: "company-logo/yogo.png",
    client: "YoGo PH",
    workAs: "Part-time",
    jobTitle: "Full Stack Developer",
    clientLocation: "Pasig City, Philippines",
    timeline: {
      from: new Date("2022-08-01"),
      to: new Date("2022-09-01"),
    },
    description: `The First & Only Affiliate-Lifestyle Marketplace Platform for everyJuan!`,
  },
  {
    imageUrl: "https://backrideph.online/images/brp.png",
    client: "Backride Palawan",
    workAs: "Full-time",
    jobTitle: "Web/Backend Developer",
    clientLocation: "Puerto Princesa, Palawan, Philippines",
    timeline: {
      from: new Date("2023-01-01"),
    },
    description: `Backride Palawan just released a completely new version of the app and I am part of the team again.`,
  },
];
function WorkExperience() {
  useTitle("Work Experience");
  const [workExperience, setWorkExperience] = useState<any[]>([]);

  useEffect(() => {
    setWorkExperience(
      workExperienceList.sort(
        (a, b) => b.timeline.from.getTime() - a.timeline.from.getTime()
      )
    );
  }, [workExperience]);

  return (
    <div className="py-5 px-5">
      <div className="text-4xl font-bold text-center mb-10">
        Work Experience
      </div>
      <div className="flex justify-center items-center">
        <div className="max-w-[800px]">
          {workExperience.map((project, i) => (
            <div
              tabIndex={i + 1}
              key={i}
              className="bg-[#221941] shadow-[0_5px_30px_-10px_rgba(0,0,0,0.3)] text-white p-4 rounded-xl mb-7 cursor-pointer group hover:scale-102 transition-default"
            >
              <div>
                <div className="flex flex-col items-center md:items-start md:flex-row gap-5 justify-start">
                  <img
                    className="w-[100px] h-[100px] rounded-full group-hover:-rotate-3 transition-default"
                    src={project.imageUrl}
                    alt={project.client}
                  />
                  <div className="w-full">
                    <div className="text-2xl text-new-blue font-semibold">
                      {project.jobTitle}
                    </div>
                    <div className="text-sm">
                      {project.client} &bull; {project.workAs}
                    </div>
                    <div className="text-sm text-white">
                      {moment(project.timeline.from).format("MMM YYYY")} -{" "}
                      {project.timeline.to
                        ? moment(project.timeline.to).format("MMM YYYY")
                        : "Present"}
                      {moment(
                        project.timeline.to ? project.timeline.to : new Date()
                      ).diff(moment(project.timeline.from), "years") >= 1 && (
                        <>
                          {" "}
                          &bull;{" "}
                          {moment(
                            project.timeline.to
                              ? project.timeline.to
                              : new Date()
                          ).diff(moment(project.timeline.from), "years")}{" "}
                          year
                          {moment(
                            project.timeline.to
                              ? project.timeline.to
                              : new Date()
                          ).diff(moment(project.timeline.from), "years") === 1
                            ? ""
                            : "s"}
                        </>
                      )}
                    </div>
                    <div className="text-sm text-white">
                      {project.clientLocation}
                    </div>
                    <blockquote className="mt-5 border-l-2 pl-3 border-l-new-blue-dark">
                      {project.description}
                    </blockquote>
                  </div>
                </div>
                {/* <hr className="my-10" /> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WorkExperience;
