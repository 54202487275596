import React, { useState } from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollToTop";
const TemplateV2: React.FC<{ content?: any }> = ({ content = "" }) => {
  const [showNavLinks, setShowNavLinks] = useState(false);
  const showSnowFlakes = false;
  return (
    <>
      <div
        style={
          {
            // backgroundImage: "url(/confetti-doodles.svg)",
          }
        }
        className="bg-theme-default h-screen overflow-y-scroll scrollbar-hide main-container"
      >
        {showSnowFlakes && (
          <>
            <div className="snowflake">❅</div>
            <div className="snowflake">❅</div>
            <div className="snowflake">❆</div>
            <div className="snowflake">❄</div>
            <div className="snowflake">❅</div>
            <div className="snowflake">❆</div>
            <div className="snowflake">❄</div>
            <div className="snowflake">❅</div>
            <div className="snowflake">❆</div>
            <div className="snowflake">❄</div>
          </>
        )}
        <header className="between">
          <Link tabIndex={1} to="/" className="start">
            <img className="w-[5rem]" src="/mc-transparent.png" alt="logo" />
            {/* <span className="text-base">Mark Cay</span> */}
          </Link>
          <div className="end w-max lg:gap-10">
            <nav className="group">
              <div
                className="bar m-1 btn !text-2xl lg:hidden"
                onClick={() => {
                  setShowNavLinks(!showNavLinks);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                  />
                </svg>

                {/* <i className="fa fa-bars"></i> */}
              </div>

              {showNavLinks && (
                <>
                  <div
                    onClick={() => {
                      setShowNavLinks(!showNavLinks);
                    }}
                    className="fixed  z-[9999] lg:hidden top-0 left-0 right-0 bottom-0 bg-black opacity-75 overflow-hidden"
                  ></div>
                  <div className="fixed  z-[9999] lg:hidden top-0 right-0 bottom-0 left-[100px] bg-dark-2024 ">
                    <div className="flex justify-end items-center p-4">
                      <i
                        onClick={() => {
                          setShowNavLinks(!showNavLinks);
                        }}
                        className="fa fa-times btn"
                      ></i>
                    </div>
                    <div className={`links gap-10 flex-col`}>
                      {/* <Link
                        tabIndex={1}
                        onClick={() => {
                          setShowNavLinks(!showNavLinks)
                        }}
                        to="/skills"
                      >
                        Skills
                      </Link> */}
                      <Link
                        tabIndex={1}
                        onClick={() => {
                          setShowNavLinks(!showNavLinks);
                        }}
                        to="/work-experience"
                      >
                        Work Experience
                      </Link>
                      <Link
                        tabIndex={1}
                        onClick={() => {
                          setShowNavLinks(!showNavLinks);
                        }}
                        to="/contact"
                      >
                        Contact
                      </Link>
                      {/* <Link
                        tabIndex={1}
                        onClick={() => {
                          setShowNavLinks(!showNavLinks)
                        }}
                        to="/blog"
                      >
                        Blog
                      </Link> */}
                    </div>

                    <a
                      rel="noreferrer"
                      tabIndex={1}
                      target="_blank"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSeS1cf9mU02uROnvBGuXm3yGBrZAO5B1TgCIE_-Esk2T8OlAg/viewform"
                      className="btn relative bg-new-blue text-white btn-outline btn-light btn-rounded !p-2 center w-100 m-5 my-10"
                    >
                      Hire me for a project
                      <i className="ml-2 fas fa-chevron-right fa-xs"></i>
                      <div className="animate-ping bg-[#ffffff44] w-[20px] h-[20px] rounded-full absolute bottom-[10px] right-[50px]"></div>
                    </a>
                  </div>
                </>
              )}

              <div className={`font-extrabold links gap-10 !hidden lg:!flex`}>
                {/* <Link to="/skills">Skills</Link> */}
                <Link to="/work-experience">Work Experience</Link>
                {/* <Link to="/projects">Projects</Link> */}
                <Link to="/contact">Contact</Link>
              </div>
            </nav>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeS1cf9mU02uROnvBGuXm3yGBrZAO5B1TgCIE_-Esk2T8OlAg/viewform"
              className="btn bg-new-blue text-white btn-outline btn-light btn-rounded !p-3 hidden relative lg:block"
            >
              Hire me for a project
              <i className="ml-2 fas fa-chevron-right fa-xs"></i>
              <div className="animate-ping bg-[#ffffff44] w-[20px] h-[20px] rounded-full absolute bottom-[15px] right-[10px]"></div>
            </a>
          </div>
        </header>

        <div className="mt-2 min-h-screen">{content}</div>
        <footer className="p-5 text-center mt-[2rem]">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="w-full lg:w-1/3 text-center lg:text-left flex flex-col gap-2">
              <div className="flex flex-row items-center justify-center lg:justify-start">
                <img
                  className="w-[5rem]"
                  src="/mc-transparent.png"
                  alt="logo"
                />
                {/* <div className="text-xl font-bold">Mark Cay</div> */}
              </div>
              <div className="text-xs">Enthusiastic about technology</div>
              <div className="text-xs text-new-blue">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:markgabrieller@gmail.com"
                >
                  {/* <i className="fa fa-envelope mr-4 text-light"></i> */}
                  markgabrieller@gmail.com
                </a>
              </div>
              <div className="text-xs text-new-blue">
                <a target="_blank" rel="noreferrer" href="tel:+639508476214">
                  {/* <i className="fa fa-phone mr-4 text-light"></i> */}
                  +63950 847 6214
                </a>
              </div>
              <div className="social-links">
                <a
                  href="https://twitter.com/markcayofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://facebook.com/Gabrielle.Cay"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  href="https://linkedin.com/in/markcay"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://github.com/markcay"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-github"></i>
                </a>
                <a
                  href="https://discordapp.com/users/781437984791592990"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-discord"></i>
                </a>
              </div>
            </div>
            <div className="w-full lg:w-2/3 flex flex-row gap-5 items-start justify-around text-sm text-left">
              <div>
                <div className="font-bold mb-3 text-base">Information</div>
                <div className="flex flex-col gap-1 text-xs">
                  <Link
                    onClick={() => {
                      scrollToTop();
                    }}
                    to="/contact"
                  >
                    Contact
                  </Link>
                  <Link
                    onClick={() => {
                      scrollToTop();
                    }}
                    to="/faqs"
                  >
                    FAQs
                  </Link>
                </div>
              </div>
              <div>
                <div className="font-bold mb-3 text-base">Links</div>
                <div className="flex flex-col gap-1 text-xs">
                  {/* <Link
                    onClick={() => {
                      scrollToTop()
                    }}
                    to="/skills"
                  >
                    Skills
                  </Link> */}
                  <Link
                    onClick={() => {
                      scrollToTop();
                    }}
                    to="/work-experience"
                  >
                    Work
                  </Link>
                  <Link
                    onClick={() => {
                      scrollToTop();
                    }}
                    to="/contact"
                  >
                    Contact
                  </Link>
                  {/* <Link
                    onClick={() => {
                      scrollToTop()
                    }}
                    to="/blog"
                  >
                    Blog
                  </Link> */}
                </div>
              </div>
              <div>
                <div className="font-bold mb-3 text-base">Privacy</div>
                <div className="flex flex-col gap-1 text-xs">
                  <Link
                    onClick={() => {
                      scrollToTop();
                    }}
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    onClick={() => {
                      scrollToTop();
                    }}
                    to="/terms-of-service"
                  >
                    Terms of Service
                  </Link>
                  <Link
                    onClick={() => {
                      scrollToTop();
                    }}
                    to="/cookie-policy"
                  >
                    Cookie Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <hr className="text-gray my-4" />
          Copyright &copy; {new Date().getFullYear()} Mark Cay. All rights
          reserved.
        </footer>
      </div>
    </>
  );
};

export default TemplateV2;
