import React, { useEffect, useState } from "react";
import Skills from "../../components/Skills";
import { useTitle } from "../../hooks/useTitle";
import Marquee from "react-fast-marquee";
import { scrollToTop } from "../../utils/scrollToTop";
import { useNavigate } from "react-router-dom";

const workExperienceList = [
  {
    imageUrl: "company-logo/zerobstacle.png",
    client: "Zerobstacle Technologies",
    workAs: "Full-time",
    jobTitle: "Senior Programmer",
    clientLocation: "Puerto Princesa, Palawan, Philippines",
    timeline: {
      from: new Date("2019-09-01"),
      to: null,
    },
    description: `At Zerobstacle Technologies, we build cross-platform mobile apps
  and web systems for ride-hailing, courier delivery, and other
  transport-related services.`,
  },
  {
    imageUrl: "company-logo/evaheld.ico",
    client: "Evaheld",
    workAs: "Freelance",
    jobTitle: "Full Stack Developer",
    clientLocation: "608 Harris Street, Ultimo NSW 2007, Australia",
    timeline: {
      from: new Date("2022-07-01"),
      to: new Date("2022-08-01"),
    },
    description: `Create personalised content for your loved ones and leave less unsaid - because connection is all we have.`,
  },
  {
    imageUrl: "company-logo/argila.png",
    client: "Argila",
    workAs: "Contract",
    jobTitle: "Angular BaaS Developer",
    clientLocation: "Pasig, National Capital Region, Philippines",
    timeline: {
      from: new Date("2022-06-01"),
      to: new Date("2022-07-01"),
    },
    description: `Argila is an online platform for individuals/companies to advertise their professional services to a looking public; individuals/companies may also post job orders that job-seekers may respond to.`,
  },
  {
    imageUrl: "company-logo/backride-palawan.png",
    client: "Backride Palawan",
    workAs: "Part-time",
    jobTitle: "Full Stack Developer",
    clientLocation: "Puerto Princesa, Palawan, Philippines",
    timeline: {
      from: new Date("2021-01-01"),
      to: new Date("2022-04-01"),
    },
    description: `Experience the convenience of RIDE, RENT, SHOP and REQUEST on this unique and beautiful Android App! By Palaweños for Palaweños!`,
  },
  {
    imageUrl: "company-logo/yogo.png",
    client: "YoGo PH",
    workAs: "Part-time",
    jobTitle: "Full Stack Developer",
    clientLocation: "Pasig City, Philippines",
    timeline: {
      from: new Date("2022-08-01"),
      to: new Date("2022-09-01"),
    },
    description: `The First & Only Affiliate-Lifestyle Marketplace Platform for everyJuan!`,
  },
  {
    imageUrl: "https://backrideph.online/images/brp.png",
    client: "Backride Palawan",
    workAs: "Full-time",
    jobTitle: "Web/Backend Developer",
    clientLocation: "Puerto Princesa, Palawan, Philippines",
    timeline: {
      from: new Date("2023-01-01"),
    },
    description: `Backride Palawan just released a completely new version of the app and I am part of the team again.`,
  },
];
const Home = () => {
  useTitle("Home");
  const navigate = useNavigate();

  const [workExperience, setWorkExperience] = useState<any[]>([]);

  useEffect(() => {
    setWorkExperience(
      workExperienceList.sort(
        (a, b) => b.timeline.from.getTime() - a.timeline.from.getTime()
      )
    );
  }, []);
  return (
    <div className="bg-dark-2024">
      <div className="flex flex-col lg:flex-row items-stretch justify-start">
        <div className="w-full lg:w-1/2 p-3 md:p-5 lg:p-10 flex items-start flex-col">
          <img
            className="h-[120px] md:h-[250px] xl:h-[300px] rounded-full transition-default hover:rotate-3"
            // src="https://thispersondoesnotexist.com/image"
            src="/markcay-halloween.jpg"
            alt="A non-existent being"
          />
          <div className="mt-2 text-xl font-extrabold">Mark Cay</div>
          <div className="text-4xl font-black">
            I develop responsive and user-friendly cross-platform apps and web
            systems ;-)
          </div>
          {/* <div>123</div> */}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeS1cf9mU02uROnvBGuXm3yGBrZAO5B1TgCIE_-Esk2T8OlAg/viewform"
            className="relative bg-new-blue btn-rounded btn  btn-outline btn-light font-bold text-white !p-3 mt-4"
          >
            Hire me for a project
            <i className="ml-2 fas fa-chevron-right fa-xs"></i>
            <div className="animate-ping bg-[#ffffff44] w-[20px] h-[20px] rounded-full absolute bottom-[15px] right-[10px]"></div>
          </a>
        </div>
        <div className="w-full lg:w-1/2 p-3 md:p-5 lg:p-10 center flex-col">
          <img
            style={{ colorScheme: "light" }}
            src="https://raw.githubusercontent.com/markcay/markcay/output/github-contribution-grid-snake-dark.svg"
            alt=""
          />
          <img
            src="https://streak-stats.demolab.com/?user=markcay&background=090417&stroke=5520ff&fire=5520ff&ring=5520ff&currStreakLabel=fff&sideLabels=fff&sideNums=fff&currStreakNum=fff&border=090417"
            className="mt-5"
            alt=""
          />
          {/* <img
            src="https://streak-stats.demolab.com/?user=markcay"
            className="mt-5"
            alt=""
          /> */}
        </div>
      </div>
      <img
        className="w-full mt-4"
        // src="https://thispersondoesnotexist.com/image"
        src="/wave.svg"
        alt="wave section"
      />
      {/* <div className="mt-[8rem]"></div> */}
      <div className="bg-new-blue text-white p-1 lg:p-5 center flex-col">
        {/* <div className="text-xl font-bold text-center mb-5">
          Previous Clients/Companies
        </div> */}
        <Marquee
          className="overflow-hidden"
          autoFill={true}
          speed={100}
          direction="right"
        >
          {workExperience.map((we, i) => (
            <div
              key={i}
              className="mx-20"
              onClick={() => {
                navigate("/work-experience");
                scrollToTop();
              }}
            >
              <div
                style={{
                  backgroundImage: "url(" + we.imageUrl + ")",
                }}
                className={`h-[100px] bg-cover bg-center bg-no-repeat w-[100px] rounded-full animate-company-${
                  i % 2
                }`}
              />
            </div>
          ))}
        </Marquee>
        <div className="mt-20"></div>
        <Skills />
      </div>
      <img
        className="w-full rotate-180 -mt-[1px] -z-[20]"
        // src="https://thispersondoesnotexist.com/image"
        src="/wave.svg"
        alt="wave section"
      />
    </div>
  );
};

export default Home;
