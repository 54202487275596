import React from 'react'
import { useTitle } from '../hooks/useTitle'

const Skills = () => {
  useTitle('Skills')
  const skills = [
    {
      header: 'Web',
      list: [
        {
          name: 'HTML',
        },
        {
          name: 'CSS',
        },
        {
          name: 'JavaScript',
        },
        {
          name: 'PHP',
        },
        {
          name: 'SQL',
        },
      ],
    },
    {
      header: 'Web Frameworks',
      list: [
        {
          name: 'CodeIgniter',
        },
        {
          name: 'Laravel',
        },
        {
          name: 'React.js',
        },
        { name: 'Vue.js' },
        { name: 'Angular.js' },
      ],
    },
    {
      header: 'Mobile App & Cross Platform (Web / Android / iOS)',
      list: [
        {
          name: 'Android',
        },
        {
          name: 'Kotlin',
        },
        { name: 'React Native' },
        { name: 'Ionic' },
      ],
    },
    {
      header: 'Cloud Databases, 3rd Party APIS, and Services',
      list: [
        {
          name: 'Google Cloud Platform',
        },
        {
          name: 'Firebase services',
        },
        { name: 'MongoDB' },
        { name: 'Apollo GraphQL (Beginner)' },
        { name: 'PayMongo' },
        { name: 'Stripe' },
      ],
    },
    {
      header: 'Design Tools and Frameworks',
      list: [
        {
          name: 'Tailwind CSS',
        },
        {
          name: 'Bootstrap',
        },
        { name: 'Figma' },
        { name: 'Canva' },
        // { name: 'Adobe XD' },
      ],
    },
    {
      header: 'Hosting and Deployment Tools',
      list: [
        {
          name: 'GitHub',
        },
        {
          name: 'GitLab',
        },
        { name: "DigitalOcean's Droplet" },
        { name: 'Firebase' },
        { name: 'Docker (Beginner)' },
        { name: 'Linode' },
        { name: 'Namecheap' },
        { name: 'cPanel & WHM' },
        // { name: "Kubernetes" },
      ],
    },
    {
      header: 'Other Technologies & Tools',
      list: [
        {
          name: 'Grunt.js',
        },
        {
          name: 'TypeScript',
        },
        {
          name: 'Java',
        },
        {
          name: 'phpMyAdmin',
        },
      ],
    },
  ]
  return (
    <div>
      <div className="text-4xl font-bold text-center mb-5">Skills</div>

      <div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 p-4">
          {skills.map((skill, i) => (
            <div
              key={i}
              className=" group p-4 px-7 rounded bg-white text-black hover:scale-102 transition-default"
            >
              <div className="group-hover:text-orange text-xl lg:text-2xl font-bold uppercase mt-4 b-t-2 text-center mb-2">
                {skill.header}
              </div>
              {skill.list.map((item, j) => (
                <div key={j} className="">
                  <i className="fa fa-arrow-right"></i>&emsp;{item.name}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Skills
