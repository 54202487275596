import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Skills from "./components/Skills";
import Contact from "./pages/Contact";
import Home from "./pages/v2/Home";
import WorkExperience from "./pages/WorkExperience";
import TemplateV2 from "./templates/v2/TemplateV2";
import Lottie from "lottie-react";
import sadLottie from "./assets/sad.json";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TemplateV2 content={<Home />} />}></Route>
        <Route
          path="/skills"
          element={
            <TemplateV2
              content={
                <>
                  {" "}
                  <div className="bg-new-blue text-white pt-10">
                    <Skills />
                  </div>
                  <img
                    className="w-full rotate-180 -mt-[1px] -z-[20]"
                    src="/wave.svg"
                    alt="wave section"
                  />
                </>
              }
            />
          }
        ></Route>
        <Route
          path="/work-experience"
          element={
            <TemplateV2
              content={
                <div>
                  <WorkExperience />
                </div>
              }
            />
          }
        ></Route>
        <Route
          path="/contact"
          element={
            <TemplateV2
              content={
                <div>
                  <Contact />{" "}
                </div>
              }
            />
          }
        ></Route>
        <Route
          path="/blog"
          element={
            <TemplateV2
              content={
                <div className="text-center">
                  <div className="text-4xl font-bold text-center mb-5">
                    Blogs
                  </div>
                  <div>No blog posts yet.</div>
                </div>
              }
            />
          }
        ></Route>
        <Route
          path="/*"
          element={
            <TemplateV2
              content={
                <div className="text-center">
                  <div className="text-3xl text-red">Error 404</div>
                  <div>The page you are looking for cannot be found.</div>
                  <div className="max-w-[400px]   rotate-[10deg] -top-[100px] z-[9999999999] mx-auto">
                    <Lottie animationData={sadLottie} loop={true} />
                  </div>
                </div>
              }
            />
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
